import React, { useState } from 'react';
import '../index.css';

const computersInitialState = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

const HomePage = () => {
  const [computers, setComputers] = useState(computersInitialState);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleComputer = (id) => {
    setComputers((prev) => {
      const updated = { ...prev, [id]: !prev[id] };
      return updated;
    });
  };

  const isLineActive = (id1, id2) => computers[id1] && computers[id2];
  const allConnected = Object.values(computers).every((status) => status);

  const handleContactClick = (event) => {
    event.preventDefault();
    setShowTooltip((prev) => !prev); // Toggle tooltip visibility
  };

  const handleManifestoClick = (event) => {
    event.preventDefault();
    setShowModal(true); // Show the modal when "Manifesto" is clicked
  };

  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };

  return (
    <div className="app-container">
      <div className={`header ${allConnected ? 'header-connected' : ''}`}>
        <a className="menu-link left-link" onClick={handleManifestoClick}>
          <u>Manifesto</u>
        </a>
        <h1 className="title">Fiftysix</h1>
        <div className="tooltip-container">
          <a className="menu-link right-link" onClick={handleContactClick}>
            <u>Contact Us</u>
          </a>
          {showTooltip && (
            <div className="tooltip">
              <a href="mailto:business@fiftysix.xyz">business@fiftysix.xyz</a>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h1 className="title">The Fiftysix Manifesto</h1>
          <br/>
          <div className="manifesto-wrapper manifesto-text">
            <p className="manifesto-text">
              We say blockchains are decentralized, but we don't mean it.
              Blockchains are becoming increasingly challenging to run and harder to afford.
              Today's payment systems are focused on speed and cost rather than trust and decentralization.
              If we aren't careful, we will revert to old currency standards.
            </p>
            <br/>
            <p className="manifesto-text">
              There are over 10,000 blockchains in existence today. Only a handful are properly decentralized.
              We talk about settlement, zero-knowledge proofs, sub-cent transaction fees, and little about decentralization.
              Blockchain innovation is irrelevant without decentralization.
            </p>
            <br/>
            <p className="manifesto-text">
              Someone needs to make blockchain accessible, efficient, and reliable.
              That's where we step in.
              Fiftysix has extensive experience building, running, and serving blockchain nodes.
              {" "} <a className="passive-link" target="_blank" rel="noopener noreferrer" href="https://nodevin.xyz">We can help you do it too.</a>
            </p>
            <br/>
            <p className="manifesto-text selected">
              <strong>
                We're Fiftysix, and we believe in decentralization.
              </strong>
            </p>
          </div>
          </div>
        </div>
      )}

      <div className="network-container">
        <svg className="pentagram">
          {/* Define lines between each pair of computers for six nodes */}
          <line className={`link ${isLineActive(1, 2) ? 'active animated' : ''}`} x1="50%" y1="25%" x2="75%" y2="40%" />
          <line className={`link ${isLineActive(1, 3) ? 'active animated' : ''}`} x1="50%" y1="25%" x2="65%" y2="75%" />
          <line className={`link ${isLineActive(1, 4) ? 'active animated' : ''}`} x1="50%" y1="25%" x2="35%" y2="75%" />
          <line className={`link ${isLineActive(1, 5) ? 'active animated' : ''}`} x1="50%" y1="25%" x2="25%" y2="40%" />
          <line className={`link ${isLineActive(1, 6) ? 'active animated' : ''}`} x1="50%" y1="25%" x2="50%" y2="90%" />
          <line className={`link ${isLineActive(2, 3) ? 'active animated' : ''}`} x1="75%" y1="40%" x2="65%" y2="75%" />
          <line className={`link ${isLineActive(2, 4) ? 'active animated' : ''}`} x1="75%" y1="40%" x2="35%" y2="75%" />
          <line className={`link ${isLineActive(2, 5) ? 'active animated' : ''}`} x1="75%" y1="40%" x2="25%" y2="40%" />
          <line className={`link ${isLineActive(2, 6) ? 'active animated' : ''}`} x1="75%" y1="40%" x2="50%" y2="90%" />
          <line className={`link ${isLineActive(3, 4) ? 'active animated' : ''}`} x1="65%" y1="75%" x2="35%" y2="75%" />
          <line className={`link ${isLineActive(3, 5) ? 'active animated' : ''}`} x1="65%" y1="75%" x2="25%" y2="40%" />
          <line className={`link ${isLineActive(3, 6) ? 'active animated' : ''}`} x1="65%" y1="75%" x2="50%" y2="90%" />
          <line className={`link ${isLineActive(4, 5) ? 'active animated' : ''}`} x1="35%" y1="75%" x2="25%" y2="40%" />
          <line className={`link ${isLineActive(4, 6) ? 'active animated' : ''}`} x1="35%" y1="75%" x2="50%" y2="90%" />
          <line className={`link ${isLineActive(5, 6) ? 'active animated' : ''}`} x1="25%" y1="40%" x2="50%" y2="90%" />

          {/* Define the background masking circles under each computer */}
          {[1, 2, 3, 4, 5, 6].map((id, index) => (
            <circle
              key={`mask-${id}`}
              cx={
                index === 0
                  ? '50%'
                  : index === 1
                  ? '75%'
                  : index === 2
                  ? '65%'
                  : index === 3
                  ? '35%'
                  : index === 4
                  ? '25%'
                  : '50%'
              }
              cy={index === 0 ? '25%' : index === 1 ? '40%' : index === 2 ? '75%' : index === 3 ? '75%' : index === 4 ? '40%' : '90%'}
              r="85"
              fill="black"
            />
          ))}

          {/* Define the computer icons */}
          {[1, 2, 3, 4, 5, 6].map((id, index) => (
            <image
              key={id}
              href="computer-icon.svg"
              className={`computer ${computers[id] ? 'active' : ''}`}
              x={
                index === 0
                  ? '50%'
                  : index === 1
                  ? '75%'
                  : index === 2
                  ? '65%'
                  : index === 3
                  ? '35%'
                  : index === 4
                  ? '25%'
                  : '50%'
              }
              y={index === 0 ? '25%' : index === 1 ? '40%' : index === 2 ? '75%' : index === 3 ? '75%' : index === 4 ? '40%' : '90%'}
              width="150"
              height="150"
              transform="translate(-75, -75)"
              onClick={() => toggleComputer(id)}
            />
          ))}
        </svg>
      </div>
      <div className="footer">
        <p className="footer-link">The Decentralization Company</p>
      </div>
    </div>
  );
};

export default HomePage;
